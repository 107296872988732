import { Helmet } from "react-helmet";
import Link from "./Link";
import Metadata from "./Metadata";

export const Head = (props: any) => {
  const title: string =
    props.title !== undefined ? props.title : "Azulejo Technologies";

  return (
    <>
      <Helmet>
        <Metadata description={title} />
        <Link />
        <title>{title}</title>
      </Helmet>
    </>
  );
};
