import {
  SERVER_HTTPS_ENDPOINT /*,reportWebVitals */,
  ServerClient,
  verifyMobXConfig,
} from "@azulejo/common";
import ReactDOM from "react-dom/client";
import App from "./app/App";

verifyMobXConfig();

const rootElement = document.getElementById("root");
if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    //<React.StrictMode>
    <App />
    //</React.StrictMode>
  );
} else {
  console.error("Root element not found");
}

// Create a DIY Sentry solution
window.onerror = function (message, source, lineno, colno, error) {
  const errorDetails = {
    message,
    source,
    lineno,
    colno,
    stack: error ? error.stack : "",
  };

  const serverClient = new ServerClient("");
  const url: string = `${SERVER_HTTPS_ENDPOINT}/api/sentry/error`;
  serverClient.fetchCall(url, serverClient.postOptions(url, errorDetails));
};

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log);
