const Metadata = (props: any) => {
  const description: string = props.description;

  return (
    <>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="theme-color" content="#000000" />
      <meta name="description" content={description} />
    </>
  );
};

export default Metadata;
