import { Alert, Container, Snackbar } from "@mui/material";
import { useState } from "react";

export const OverlaySnackbar = (props: any) => {
  const children: any = props.children;
  const autoHideDuration: number = props.autoHideDuration;
  const sx: any = props.sx;
  const [open, setOpen] = useState(true);

  const handleClose = (event: any) => {
    setOpen(false);
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      sx={{ width: "100%", opacity: "80%" }}
    >
      <Container>
        <Alert
          onClose={handleClose}
          severity="warning"
          sx={{
            alignItems: "center",
            justifyItems: "center",
            justifyContent: "center",
            alignContent: "center",
            display: "flex",
            width: "100%",
            ...sx,
          }}
        >
          {children}
        </Alert>
      </Container>
    </Snackbar>
  );
};
