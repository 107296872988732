import { Auth0Provider } from "@auth0/auth0-react";
import React from "react";

interface AuthProps {
  component: React.ReactNode;
  appName: string;
  domain: string;
  clientId: string;
}

export const Auth0ProviderWrapper = (props: AuthProps) => {
  const appName: string = props.appName;
  const domain: string = props.domain;
  const clientId: string = props.clientId;

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: window.location.origin + `/${appName}/app`,
      }}
    >
      {props.component}
    </Auth0Provider>
  );
};
