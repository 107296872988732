import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { useTheme } from "@mui/system";

import { AccordionItem, AccordionList } from "../../accordions/AccordionList";

export const FAQAccordion = (props: any) => {
  const items: AccordionItem[] = props.items;
  const sx: any = props.sx;
  const text: string = props.text;

  const theme: any = useTheme();

  return (
    <Box id="faq">
      <Container
        sx={{
          pt: { xs: 4, sm: 12 },
          pb: { xs: 8, sm: 16 },
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: { xs: 3, sm: 6 },
          ...sx,
        }}
      >
        <Typography
          component="h2"
          variant="h2"
          sx={{
            color: "text.primary",
            width: { sm: "100%", md: "60%" },
            textAlign: { sm: "left", md: "center" },
            fontFamily: theme.typography.fontFamily,
          }}
        >
          {text}
        </Typography>
        <Box sx={{ width: "100%" }}>
          <AccordionList items={items} />
        </Box>
      </Container>
    </Box>
  );
};
